import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import AOS from 'aos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../../components/Layout'
import Section from '../../../components/Section'
import Hero from '../../../components/Hero'
import FeaturedPlatformPagesSection from '../../../components/FeaturedPlatformPagesSection'
import LatestResourcesSection from '../../../components/LatestResourcesSection'
import FaqSection from '../../../components/FaqSection'
import CustomerStorySliderSection from '../../../components/CustomerStorySliderSection'
import PlatformFeaturesSection from '../../../components/PlatformFeaturesSection'
import BookDemoSection from '../../../components/BookDemoSection'
import {bookADemoLinkSwitch} from '../../../components/lib/bookADemoLinkSwitch'

import featureImageBetterInsights from '../../../images/features2023/performance-reviews/Feature_Images/better-insights.png';
import featureImageEmployeeDevelopment from '../../../images/features2023/performance-reviews/Feature_Images/employee-development.png';
import featureImageEnhancedCollaboration from '../../../images/features2023/performance-reviews/Feature_Images/enhanced-collaboration.png';
import featureImageFullyCustomisable from '../../../images/features2023/performance-reviews/Feature_Images/fully-customisable.png';
import featureImageExpertSupport from '../../../images/features2023/performance-reviews/Feature_Images/hr-advisor.png';
import bannerIcon1 from '../../../images/features2023/hiring-and-onboarding/Top_Banner_Icons/23-129_MyHR_Onboarding_Platform_Page_Paperless.png'
import bannerIcon2 from '../../../images/features2023/hiring-and-onboarding/Top_Banner_Icons/23-129_MyHR_Onboarding_Platform_Page_Employee-experience.png'
import bannerIcon3 from '../../../images/features2023/performance-reviews/Top_Banner_Icons/23-140_MyHR_SupportAsset 1.png'

import platformFeatureSmartNotes from '../../../images/platform/features/smart-notes.png';
import platformFeatureLmsIntegration from '../../../images/platform/features/lms-integration.png';
import platformFeaturePerformanceReviewTemplate from '../../../images/platform/features/performance-review-template.png';
import platformFeatureCustomisableReview from '../../../images/platform/features/customisable-review.png';
import platformFeatureSkillLibrary from '../../../images/platform/features/skill-library.png';
import platformFeatureInsightsAnalytics from '../../../images/platform/features/insights-analytics.png';
import platformFeatureRatingScale from '../../../images/platform/features/rating-scale.png';
import platformFeatureAiFeature from '../../../images/platform/features/ai-feature.png';
import platformFeatureReminder from '../../../images/platform/features/reminder.png';
import customerStories from '../../../data/customerStories'

import '../../platform.scss'

const PAGE_CONFIG = {
  locale: "en-NZ",
  meta: {
    title: "Performance Review & Performance Management Software",
    description: "Build an engaged and productive team with our employee management and performance review software. Start your free trial today."
  },
  tourLink: "https://app.storylane.io/share/t75xpdiqclsx",
}

export default () => {
  const demoLink = bookADemoLinkSwitch(PAGE_CONFIG.locale);

  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  return (

    <Layout
      className="PlatformPage"
      title={PAGE_CONFIG.meta.title}
      description={PAGE_CONFIG.meta.description}
      locale={PAGE_CONFIG.locale}
    >
      <Hero variant="tall">
        <h1>Run seamless<br/> performance reviews</h1>
        <p>
          Make performance reviews stress-free, with clear objectives, simple tracking and appraisals, and rich oversight and reporting.
        </p>
        <Button
          color="cyan"
          href={demoLink}
          className="mr-3"
        >
          Book a demo
        </Button>
        <Button
          color="outline-cyan"
          href={PAGE_CONFIG.tourLink}
          target="_blank"
        >
          <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
          Take the tour
        </Button>
      </Hero>

      <Section className="BannerIconSection">
        <Container>
          <Row className="">
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon1} />
              <p>Streamline the performance review process</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon2} />
              <p>Work together to set and track objectives, and complete appraisals</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon3} />
              <p>Set clear, actionable objectives and goals</p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="Content greyBack">
        <Container>

          <Row className="mt-5 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <div class="headline-title">Employee development</div>
              <h3>End-to-end performance reviews</h3>
              <p>
                Cultivate a culture of excellence and back it up with robust performance management and development plans. Set performance expectations, collaborate with your team on evaluation and goal-setting, and reduce your workload with our template library.
              </p>
              <a className="sectionLinkArrow" href={demoLink}>Book a demo <span>&rarr;</span></a>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={featureImageEmployeeDevelopment} className="" />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={featureImageEnhancedCollaboration} className="" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <div class="headline-title">Enhanced collaboration</div>
              <h3>Improve employee performance through collaboration</h3>
              <p>
                Now, both employers and employees can collaborate on a person's performance review. No more top-down dictation; instead, work together on draft objectives and goals through MyHR's software. Monitor progress, leave comments, and keep track of employee accomplishments with MyHR’s Smart Notes feature.
              </p>
              <a className="sectionLinkArrow" href={demoLink}>Book a demo <span>&rarr;</span></a>
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <div class="headline-title">Fully customisable</div>
              <h3>Customisable reviews to suit your business needs</h3>
              <p>
                Tailor the review style to suit your organisation's unique needs with flexible templates that cater to KPIs, goals, OKRs, and other relevant metrics. Use our AI integration to generate a template from scratch or choose from a range crafted by our HR experts.
              </p>
              <a className="sectionLinkArrow" href={demoLink}>Book a demo <span>&rarr;</span></a>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={featureImageFullyCustomisable} className="" />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={featureImageBetterInsights} className="" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <div class="headline-title">Better insights</div>
              <h3>Build a detailed picture of employee performance</h3>
              <p>
                Our Performance Reviews Dashboard gives you a complete picture of employee activity and engagement, so you can ensure your team is always focused on its goals. Easily track when your reviews were last updated by a manager or employee, generate reports, and get snapshots of the review status for your entire business or organisation.
              </p>
              <a className="sectionLinkArrow" href={demoLink}>Book a demo <span>&rarr;</span></a>
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <div class="headline-title">Expert support</div>
              <h3>Your dedicated HR Team</h3>
              <p>
                Empowering our HR  software stands an elite team of dedicated HR professionals, providing unmatched platform support and generating all your employee documentation, and providing expert advice for any employment issue. Our team does the HR heavy lifting so you can focus on growing your business.
              </p>
              <a className="sectionLinkArrow" href="/nz/platform/hr-compliance-and-advisory/">HR Advisory support <span>&rarr;</span></a>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={featureImageExpertSupport} className="" />
            </Col>
          </Row>
        </Container>
      </Section>

      <CustomerStorySliderSection
        stories={[
          {
            ...customerStories['carpet-court'],
            "moreStoriesButton": true
          }
        ]}
      />

      <PlatformFeaturesSection
        title="Performance review features"
        intro="Our suite of performance features makes running seamless performance reviews easy."
        features={[
          {
            title: "Smart notes",
            description: "MyHR’s popular Smart Notes feature lets you keep track of employee achievements, training needs, and successes in real time.",
            image: platformFeatureSmartNotes
          },
          {
            title: "LMS integration",
            description: "Seamlessly integrate with ELMO LMS to facilitate learning and development in your organisation.",
            image: platformFeatureLmsIntegration
          },
          {
            title: "Automated templates library",
            description: "Generate templates automatically based on the role, or select from a range of templates crafted by our HR experts.",
            image: platformFeaturePerformanceReviewTemplate
          },
          {
            title: "Customisable review styles",
            description: "Flexible templates that cater for KPIs, goals, OKRs and many more.",
            image: platformFeatureCustomisableReview
          },
          {
            title: "Objective and skills library",
            description: "Select from a detailed list of skills and objectives, to speed up the time spent on reviews.",
            image: platformFeatureSkillLibrary
          },
          {
            title: "Insights & analytics",
            description: "Keep track of when your reviews were last updated by a manager or employee.",
            image: platformFeatureInsightsAnalytics
          },
          {
            title: "Rating scale",
            description: "A collaborative feature that allows both manager and employee to rate performance against an objective.",
            image: platformFeatureRatingScale
          },
          {
            title: "AI feature",
            description: "Use the power of AI to generate a template using MyHR’s job description library.",
            image: platformFeatureAiFeature
          },
          {
            title: "Add to calendar",
            description: "Never miss a review by adding reminders and regular check-in reminders to your calendar.",
            image: platformFeatureReminder
          }
        ]}
      />

      <FeaturedPlatformPagesSection title="Check out our other features"/>    

      <LatestResourcesSection />

      <FaqSection 
        title="Employee performance FAQs"
        items={[
          {
            question: "How do I choose performance management software?",
            answer: (
              <p>
                To effectively manage employee performance, you need responsive software that takes care of all the admin-related tasks so you can 
                focus on talking to your people. The platform should enable easy performance tracking, scheduling of events, targets, and check-ins, 
                and collation of all employee performance data.
              </p>
            ),
          },
          {
            question: "How do I create a performance and development plan for my employees?",
            answer: (
              <>
                <p>
                  Start by defining the work that needs to be done to achieve your company goals and how the role contributes. Then work with each 
                  employee to identify key objectives, talents, and areas for development. Focus on open, honest conversations to build trust and bake 
                  in success and growth.
                </p>
                <p>
                  <a href="https://blog.myhr.works/managing-employee-performance-and-development">Read more performance & development tips</a>
                </p>
              </>
            )
          },
          {
            question: "What is the best performance management platform?",
            answer: (
              <p>
                MyHR makes reviewing and managing performance simple and painless, focusing on regular, two-way performance conversations and 
                delivering results, not filling out forms. Our intuitive software is backed by a team of HR experts to help set up and manage 
                every step of the performance review process.
              </p>
            )
          },
          {
            question: "How do I deliver an effective performance review?",
            answer: (
              <>
                <p>
                  Performance reviews are all about conversations, not paperwork. To be effective, they should be tailored to the objectives of the 
                  company and the role, accurately tracking performance and regularly checking-in. You’re aiming for a dynamic system that encourages 
                  success and development, and spots problems early, so you can address them.
                </p>
                <p>
                  <a href="https://blog.myhr.works/blog-5-steps-to-running-a-good-performance-review">Read more performance review tips</a>
                </p>
              </>
            )
          }
        ]}
      />

      <BookDemoSection
        title="Make HR easy"
        text={(
          <p>
            Create a seamless performance review cycle that provides a detailed view of individual performance and enhances the employee experience and engagement.
          </p>
        )}
        variant="compact"
        showTrialButton={false}
        extraButtons={
          <Button
            color="outline-cyan"
            href={PAGE_CONFIG.tourLink}
            target="_blank"
          >
            <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
            Take the tour
          </Button>
        }
      />
    </Layout>
  )
}
